import {
  Card,
  Box,
  Stack,
  Image,
  Title,
  Text,
  Divider,
  Anchor,
  MediaQuery,
} from "@mantine/core";
import profile from "../assets/images/profile.jpg";

const Research = () => {
  return (
    <Stack mt="md">
      <MediaQuery smallerThan="xl" styles={{ display: "none" }}>
        <Box pos="relative">
          <Image src={profile} radius="sm" />
          <Card
            pos="absolute"
            withBorder
            shadow="md"
            sx={{ borderTop: "2px solid gray" }}
            bottom={10}
            right={10}
            w="40%"
          >
            <Stack sx={{ display: "flex" }}>
              <Title order={2} color="dark.8">
                Research Direction
              </Title>
              <Divider />
              <Text size="sm" color="dark.9">
                My research is focused on investigating the neural mechanisms
                underlying flexible navigation. I plan to use a combination of
                behavioral tasks, lesion studies, and neuroimaging techniques to
                better understand the specific roles of the dorsal striatum and
                hippocampus in flexible navigation and how they interact with
                other brain regions to support adaptive behavior. I believe that
                by understanding these neural mechanisms, we will be able to
                gain insights into the neural basis of cognitive processes such
                as decision-making, planning, and problem-solving. Additionally,
                I intend to investigate neural responses during spatial
                navigation using a biophysically plausible framework explained
                by reinforcement learning models and observe the interaction
                between cortical areas and both the dorsal striatum and
                hippocampus during flexible navigation.{" "}
                {/* Current Affiliations */}
                I am currently based at the University of Pennsylvania working with <Anchor href="https://www.psych.upenn.edu/people/russell-epstein" target="_blank">Dr. Russell Epstein</Anchor>.
              </Text>
            </Stack>
          </Card>
        </Box>
      </MediaQuery>
      <MediaQuery largerThan="xl" styles={{ display: "none" }}>
        <Card withBorder shadow="md" sx={{ borderTop: "2px solid gray" }}>
          <Stack sx={{ display: "flex" }}>
            <Title order={2} color="dark.8">
              Research Direction
            </Title>
            <Divider />
            <Text size="sm" color="dark.9">
              My research is focused on investigating the neural mechanisms
              underlying flexible navigation. I plan to use a combination of
              behavioral tasks, lesion studies, and neuroimaging techniques to
              better understand the specific roles of the dorsal striatum and
              hippocampus in flexible navigation and how they interact with
              other brain regions to support adaptive behavior. I believe that
              by understanding these neural mechanisms, we will be able to gain
              insights into the neural basis of cognitive processes such as
              decision-making, planning, and problem-solving. Additionally, I
              intend to investigate neural responses during spatial navigation
              using a biophysically plausible framework explained by
              reinforcement learning models and observe the interaction between
              cortical areas and both the dorsal striatum and hippocampus during
              flexible navigation.{" "}
              {/* Current Affiliations */}
              I am currently based at the University of Pennsylvania working with <Anchor href="https://www.psych.upenn.edu/people/russell-epstein" target="_blank">Dr. Russell Epstein</Anchor>.
            </Text>
          </Stack>
        </Card>
      </MediaQuery>
    </Stack>
  );
};
export default Research;
