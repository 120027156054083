import { Carousel } from "@mantine/carousel";
import {
  Text,
  Title,
  Card,
  UnstyledButton,
  Stack,
  Divider,
  Box,
} from "@mantine/core";

import { papers } from "../utils/papers";

const cards = papers.map((paper, index) => {
  return (
    <Carousel.Slide key={index}>
      <UnstyledButton component="a" href={paper.link}>
        <Card withBorder shadow="xl" sx={{ borderTop: "2px solid gray" }}>
          <Stack justify="space-between">
            <Title order={4} color="dark.8">
              {paper.title}
            </Title>
            <Box>
              <Divider />
              <Text size="sm" color="dark.9">
                {paper.journal} | {paper.year}
              </Text>
            </Box>
          </Stack>
        </Card>
      </UnstyledButton>
    </Carousel.Slide>
  );
});

const MyCarousel = () => {
  return (
    <Carousel
      w="100%"
      mx="auto"
      slideGap="xs"
      slideSize="33.33%"
      height={400}
      loop={true}
      breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: "sm" }]}
      mt="xl"
    >
      {cards}
    </Carousel>
  );
};
export default MyCarousel;
