import { Stack, Text, Card, Group, Anchor, Title } from "@mantine/core";
import { papers } from "../utils/papers";

const rows = papers.map((paper, index) => {
  return (
    <Card
      key={index}
      withBorder
      shadow="md"
      sx={{ borderLeft: "2px solid gray" }}
    >
      <Group position="apart">
        <Stack sx={{ maxWidth: "60%" }}>
          <Title order={6} color="dark.8">
            {paper.title}
          </Title>
          <Text size="xs" color="dark.9">
            {paper.authors} |{" "}
            <Text component="span" color="blue">
              {paper.journal}
            </Text>
          </Text>
        </Stack>
        <Group>
          <Text color="dark.2" size="sm">
            {paper.year}
          </Text>
          <Anchor href={paper.link}>Link</Anchor>
        </Group>
      </Group>
    </Card>
  );
});

const Publications = () => {
  return <Stack mt="md">{rows}</Stack>;
};
export default Publications;
