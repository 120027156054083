import {
  IconBrandGithub,
  IconBrandGoogle,
  IconBrandTwitter,
  IconDownload,
} from "@tabler/icons";
import { NavLink as RouterLink } from "react-router-dom";
import { ActionIcon, Anchor } from "@mantine/core";
import { socials } from "../utils/socials";
import cv from "../assets/files/cv.pdf";

const NavContent = ({setOpened}) => {
  return (
    <>
      <Anchor component={RouterLink} to="/publications" onClick={() => setOpened(false)}>
        Publications
      </Anchor>
      <Anchor component={RouterLink} to="/research" onClick={() => setOpened(false)}>
        Research
      </Anchor>
      <ActionIcon component="a" href={socials.google} target="_blank" onClick={() => setOpened(false)}>
        <IconBrandGoogle stroke={1} size={20} color="black" />
      </ActionIcon>
      <ActionIcon component="a" href={socials.github} target="_blank" onClick={() => setOpened(false)}>
        <IconBrandGithub stroke={1} size={20} color="black" />
      </ActionIcon>
      <ActionIcon component="a" href={socials.twitter} target="_blank" onClick={() => setOpened(false)}>
        <IconBrandTwitter stroke={1} size={20} color="black" />
      </ActionIcon>
      <ActionIcon component="a" href={cv} download onClick={() => setOpened(false)}>
        <IconDownload stroke={1} size={20} color="black" />
      </ActionIcon>
    </>
  );
};
export default NavContent;
