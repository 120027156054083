import { Stack, Text } from "@mantine/core";

const PageFooter = () => {
  return (
    <Stack
      align="center"
      justify="center"
      w="100%"
      sx={{ position: "absolute", bottom: 0 }}
    >
      <Text fz="xs" color="dark.3">
        Copyright &#169; 2023 Uparity Ltd
      </Text>
    </Stack>
  );
};
export default PageFooter;
