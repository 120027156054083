import {
  Stack,
  Image,
  Button,
  Box,
  Title,
  Group,
  Text,
  MediaQuery,
} from "@mantine/core";
import bg from "../assets/images/bg.svg";
import MyCarousel from "../components/Carousel";
import { IconDownload } from "@tabler/icons";
import cv from "../assets/files/cv.pdf";

const Home = () => {
  const largeFont = "3rem";
  const smallFont = "2rem";
  return (
    <Stack mt="xl">
      <Box sx={{ position: "relative" }}>
        <MediaQuery smallerThan="md" styles={{ display: "none" }}>
          <Image src={bg} alt="background" radius="sm" height={350} />
        </MediaQuery>
        <MediaQuery largerThan="md" styles={{ display: "none" }}>
          <Image src={bg} alt="background" radius="sm" height={250} />
        </MediaQuery>
        <Stack
          m="xl"
          sx={{ position: "absolute", bottom: 0 }}
          align="flex-start"
        >
          <MediaQuery smallerThan="md" styles={{ display: "none" }}>
            <Stack>
              <Title color="white" fz={largeFont}>
                Flexible
              </Title>
              <Title color="white" fz={largeFont}>
                Computational
              </Title>
              <Title color="white" fz={largeFont}>
                Spatial Memory
              </Title>
            </Stack>
          </MediaQuery>
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Stack>
              <Title color="white" fz={smallFont}>
                Flexible
              </Title>
              <Title color="white" fz={smallFont}>
                Computational
              </Title>
              <Title color="white" fz={smallFont}>
                Spatial Memory
              </Title>
            </Stack>
          </MediaQuery>
          <Button component="a" download href={cv} radius="xs">
            <Group position="apart">
              <Text>Download CV</Text>
              <IconDownload stroke={1} color="white" />
            </Group>
          </Button>
        </Stack>
      </Box>
      <MyCarousel />
    </Stack>
  );
};
export default Home;
