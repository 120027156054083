export const papers = [
  {
    title:
      "Striatal and hippocampal contributions to flexible navigation in rats and humans",
    journal: "Brain and Neuroscience Advances",
    authors: "CJ Gahnstrom, HJ Spiers",
    link: "https://journals.sagepub.com/doi/full/10.1177/2398212820979772",
    year: "2020",
  },
  {
    title:
      "Circuit mechanisms for the chemical modulation of cortex-wide network interactions and behavioral variability",
    journal: "Science Advances",
    authors:
      "Thomas Pfeffer, Adrian Ponce-Alvarez, Konstantinos Tsetsos, Thomas Meindertsma, Christoffer Julius Gahnström, Ruud Lucas van den Brink, Guido Nolte, Andreas Karl Engel, Gustavo Deco, Tobias Hinrich Donner",
    link: "https://www.science.org/doi/pdf/10.1126/sciadv.abf5620",
    year: "2021",
  },
  {
    title:
      "Neural basis of route-planning and goal-coding during flexible navigation",
    journal: "University College London",
    authors: "CJ Gahnstrom",
    link: "https://ethos.bl.uk/OrderDetails.do?uin=uk.bl.ethos.863773",
    year: "2022",
  },
  {
    title:
      "Entropy of city street networks linked to future spatial navigation ability",
    journal: "Nature",
    authors:
      "Antoine Coutrot, Ed Manley, Sarah Goodroe, Christoffer Gahnstrom, Gabriele Filomena, Demet Yesiltepe, RC Dalton, Jan M Wiener, Christian Hölscher, Michael Hornberger, HJ Spiers",
    link: "https://www.nature.com/articles/s41586-022-04486-7?ref=refind",
    year: "2022",
  },
  {
    title:
      "Uncovering the Hippocampal Mechanisms Underpinning Spatial Learning and Flexible Navigation",
    journal: "Journal of Neuroscience",
    authors: "N Nyberg, C Gahnstrom",
    link: "https://www.researchgate.net/profile/Nils-Nyberg/publication/367075146_Uncovering_the_Hippocampal_Mechanisms_Underpinning_Spatial_Learning_and_Flexible_Navigation/links/63c039197ecd35045c421810/Uncovering-the-Hippocampal-Mechanisms-Underpinning-Spatial-Learning-and-Flexible-Navigation.pdf",
    year: "2022",
  },
  {
    title:
      "Video gaming, but not reliance on GPS, is associated with spatial navigation performance",
    journal: "bioRxiv",
    authors: "Emre Yavuz, Chuanxiuyue He, Sarah Goodroe, Christoffer Gahnstrom, Antoine Coutrot, Michael Hornberger, Mary Hegarty, Hugo J Spiers",
    link: "https://www.biorxiv.org/content/10.1101/2023.08.10.552365v1.abstract",
    year: "2023",
  },
];
