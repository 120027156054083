import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Publications, Research } from "./pages";
import SharedLayout from "./pages/SharedLayout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Home />} />
          <Route path="publications" element={<Publications />} />
          <Route path="research" element={<Research />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
