import { Box, MediaQuery, Space } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Nav from "../components/Nav";
import PageFooter from "../components/PageFooter";

const SharedLayout = () => {
  return (
    <Box bg="gray.1">
      <Nav />
      <MediaQuery largerThan="sm" styles={{ width: "60%" }}>
        <Box
          sx={{
            minHeight: "100vh",
            maxWidth: "1440px",
            position: "relative",
            width: "90%",
            margin: "0 auto",
          }}
        >
          <Outlet />
          <Space h="md" />
          <PageFooter />
        </Box>
      </MediaQuery>
    </Box>
  );
};
export default SharedLayout;
