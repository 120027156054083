import {
  Header,
  Group,
  Burger,
  Modal,
  Stack,
  Text,
  Box,
  MediaQuery,
  Anchor,
} from "@mantine/core";
import { NavLink as RouterLink } from "react-router-dom";
import { useState } from "react";
import NavContent from "./NavContent";

const Nav = () => {
  const [opened, setOpened] = useState(false);
  return (
    <Header
      height={{ base: 60, md: 80 }}
      p="xs"
      withBorder
      sx={{ borderBottom: "2px solid gray" }}
    >
      <MediaQuery largerThan="sm" styles={{ width: "60%" }}>
        <Box
          sx={{
            minHeight: "100vh",
            maxWidth: "1440px",
            position: "relative",
            width: "90%",
            margin: "0 auto",
          }}
        >
          <Group position="apart">
            <MediaQuery smallerThan="md" styles={{ display: "none" }}>
            <Anchor component={RouterLink} to="/" onClick={() => setOpened(false)}>
              <Text color="dark.8" fz="3rem">
                Chris Gahnstrom
                <Text fw={400} component="span" color="dark.9">
                  , PhD
                </Text>
              </Text>
            </Anchor>
            </MediaQuery>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Text color="dark.8" fz={24}>
                Chris Gahnstrom
                <Text fw={400} component="span" color="dark.9">
                  , PhD
                </Text>
              </Text>
            </MediaQuery>
            <Burger opened={opened} onClick={() => setOpened((o) => !o)} />
            <Modal
              opened={opened}
              onClose={() => setOpened(false)}
              size="auto"
              centered
              withCloseButton={false}
            >
              <Stack align="center">
                <NavContent />
              </Stack>
            </Modal>
          </Group>
        </Box>
      </MediaQuery>
    </Header>
  );
};
export default Nav;
